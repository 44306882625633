@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Titillium+Web:wght@400;600&display=swap');

* {
  border-radius: 8px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  color: #626873;
}
a:focus, a:hover {
  outline: none !important;
}
body {
  background-color: #f9f9f9;
}
option {
  border-radius: 0px !important;
}
ul {
  list-style-type: none;
}
h1 {
  font-size: 22px;
  padding: 6px 16px;
  font-weight: 400;
  color: #e1e7ff;
  min-height: 38px;
}
h2 {
  font-size: 16px;
  color: #343539;
  padding: 5px 0 9px 0;
  border-radius: 0px !important;
  border-bottom: 1px solid #DFDFE2;
  margin-bottom: 16px;
}
h3 {
  color: #343539;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
}
h4 {
  color: #343539;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
}
h5 {
  color: #626873;
  font-size: 12px;
  margin-bottom: 5px;
}
h1.title {
  padding: 4px 0 36px 0;
  margin: 0;
}
h1.title a {
  padding: 0;
  font-size: 22px;
  font-weight: 400;
  color: #343539;
}
h3.title {
  margin: 32px 0;
  font-weight: 700;
}
.inline {
  display: inline-block;
  margin-right: 10px;
}
.content {
  padding: 88px 32px 32px 32px;
  min-height: 100vh;
  overflow-x: hidden;
  padding-bottom: 67px;
  margin-bottom: -67px;
}
.footer {
  background-color: #343539;
  border-radius: 0px !important;
  padding: 10px 15px;
  width: 100%;
  height: 67px;
}
.footer p {
  margin: 0;
}
.footer-info {
  border-radius: 0px !important;
  border-left: 3px solid #D44200;
  margin-top: 8px;
}
.footer-title {
  font-family: 'Titillium', sans-serif;
  margin-left: 8px;
  padding-left: 8px;
  font-size: 13px;
  color: #fff;
  line-height: 1rem;
}
.footer-title span {
  font-family: 'Titillium', sans-serif;
  font-weight: 200;
  display: block;
  font-size: 10px;
  color: #fff;
  line-height: 1rem;
}
.footer-legal {
  font-family: 'Titillium', sans-serif;
  font-weight: 200;
  display: block;
  font-size: 10px;
  color: #fff;
  line-height: 1rem;
}
.footer-logo {
  padding: 12px
}
.modal-backdrop {
  border-radius: 0px !important;
}
.modal-footer {
  padding: 0.5rem;
}
.modal-footer button {
  padding: .25rem .5rem !important;
  font-size: .875rem !important;
}
.modal-hide {
  display: none;
}
.btn-create, .btn-create:hover, .btn-create:focus {
  margin-bottom: 24px;
  background-color: #f9f9f9 !important;
  border-color: #D44200 !important;
  color: #D44200;
}
.btn-remove, .btn-remove:hover, .btn-remove:focus {
  margin-bottom: 12px;
  background-color: #EEEEEE !important;
  border-color: #EEEEEE !important;
  color: #626873;
}
.btn-delete, .btn-delete:hover, .btn-delete:focus {
  background-color: #D44200;
  border-color: #D44200;
  color: #fff;
}
.btn {
  font-size: 14px;
  font-weight: normal;
  padding: .45rem .9rem;
}
.btn-primary, .btn-primary:hover, .btn-primary:focus {
  background-color: #D44200;
  border-color: #D44200;
  padding: 12px 16px;
}
.btn-actions {
  display: block;
  margin-top: 0 !important;
  text-align: left;
  padding: .35rem .7rem;
}
.btn-map, .btn-map:hover, .btn-map:focus {
  padding: 0.25rem 0.5rem;
  font-size: .875rem;
  line-height: 1.5;
  margin-top: 2rem;
  margin-left: 0 !important;
  text-decoration: underline;
  color: #D44200 !important;
}
.btn-transparent {
  border: 1px solid #DFDFE2;
  background-color: transparent;
  border-radius: 0px !important;
  border-collapse: separate;
}
.login {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  max-width: 512px;
  padding: 60px;
  margin-top: 70px;
}
.login .title {
  margin-top: 40px;
  margin-bottom: 22px;
  color: #000;
}
.login .actions {
  margin-top: 10px;
}
.login .actions button {
  margin-left: 24px;
}
.btn-link, .btn-link:hover {
  color: #626873
}
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.login-stage {
  font-size: 14px;
  margin-top: 6px;
  margin-bottom: 0;
  color: rgba(128,128,128, 0.6)
}
.navbar {
  background-color: #006FA2;
  border-radius: 0px !important;
  color: #fff;
  border: none;
}
.navbar-brand {
  font-weight: bold;
  color: #777 !important;
}
.navbar-stage {
  font-size: 14px;
  display: inline;
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.6)
}
.navbar-toggler {
  font-size: 1rem;
  padding: .25rem .50rem;
}
.navbar-actions a {
  color: #fff !important;
  font-size: 14px;
}
.navbar-sidebar a {
  font-family: 'Titillium Web', sans-serif;
  font-size: 12px;
  padding: 8px;
  color: #fff;
  width: 100%;
}
.navbar-sidebar a:hover,
.navbar-sidebar a.active {
  background-color: #F9F9F9;
  border-left: 3px solid #D44200;
  color: #626873;
}
.navbar-sidebar a:hover .icon,
.navbar-sidebar a.active .icon {
  filter: invert(1)
}
.nav-pills {
  margin-bottom: 12px;
  border-bottom: 1px solid #eee;
}
.nav-pills a {
  color: #626873;
  border-radius: 0px !important;
  background-color: #F9F9F9 !important;
}
.nav-pills a.active, .nav-pills a:hover {
  background-color: #F9F9F9 !important;
  border-bottom: 2px solid #006FA2 !important;
  color: #006FA2 !important;
}
.sidebar {
  position: fixed;
  top: 56px;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 0;
  height: calc(100vh - 56px);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  background-color: #343539;
  border-radius: 0px !important;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 12px;
}
.sidebar ul {
  padding: 0;
}
.sidebar a {
  font-family: 'Titillium Web', sans-serif;
  border-radius: 0px !important;
  font-size: 12px;
  padding: 8px;
  color: #fff;
}
.tab-pane {
  padding-top: 12px;
}
.list-group {
  margin-bottom: 1rem;
}
.list-group-item {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  margin-bottom: 8px;
  border: none;
  color: #343539;
}
.list-group-container {
  position: relative;
}
.list-group-action {
  font-size: 14px;
  color: #626873;
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 1000;
}
.card {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  margin-bottom: 8px;
  border: none;
}
.card-check .card-header::before {
  content: "•";
  color: #EEEEEE;
  font-weight: bold;
  display: inline-block;
  font-size: 2rem;
  position: absolute;
  top: .5rem;
  left: 1rem;
}
.card-check .checked::before {
  color: #0A8A0E;
}
.card-check button {
  margin-left: 1rem;
}
.card-header {
  padding: 0;
  background-color: #fff;
  border: none;
}
.card-header button {
  font-size: 16px;
  color: #343539;
}
.card-body {
  border-top: 1px solid #DFDFE2;
  border-radius: 0px !important;
  margin: 0 16px;
  padding: 16px 0;
}
.accordion {
  margin-bottom: 1rem;
}
.accordion-body {
  padding: unset
}
.accordion-button {
  display: unset;
  background-color: unset;
}
.accordion-button:not(.collapsed) {
  color: unset;
  background-color: unset;
  box-shadow: unset;
}
.icon-power {
  background:url(../public/icon-power.svg);
  margin: 3px 8px;
  width:16px;
  height:16px;
  display: block;
  float: left;
}
.icon-shield {
  background:url(../public/icon-shield.svg);
  margin: 0 4px;
  width:24px;
  height:24px;
  display: block;
  float: left;
}
.icon-chart {
  background:url(../public/icon-chart.svg);
  margin: 0 4px;
  width:24px;
  height:24px;
  display: block;
  float: left;
}
.icon-activity {
  background:url(../public/icon-activity.svg);
  margin: 0 4px;
  width:24px;
  height:24px;
  display: block;
  float: left;
}
.icon-arrow {
  background:url(../public/icon-arrow.svg);
  margin: 0 4px;
  width:24px;
  height:24px;
  display: block;
  float: left;
}
.icon-vertical-dots {
  background:url(../public/icon-vertical-dots.svg);
  margin: 6px 12px;
  width:4px;
  height:18px;
  display: block;
  float: left;
}
.no-padding {
  padding: 0;
}
.no-margin {
  margin: 0;
}
.margin-top {
  margin-top: 12px;
}
.margin-bottom {
  margin-bottom: 24px;
}
.bg-white {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  margin-bottom: 16px;
  padding: 6px 16px 24px;
  border: none;
}
.bg-white--secondary {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  margin-bottom: 16px;
  padding: 6px 16px;
  border: none;
}
.form-check {
  margin-bottom: 10px;
}
.form-order {
  width: 48px;
  text-align: center;
}
.hr-login {
  margin: 2rem 0;
}
.title-login {
  margin-top: 80px;
  margin-bottom: 22px;
  color: #333;
}
div:has(> .form-control) {
  margin-bottom: 1rem;
}
.btn-catalog, .btn-catalog:hover, .btn-catalog:focus {
  background-color: #ffffff;
  border-color: #D44200;
  color: #D44200;
  padding: 12px 24px;
  border-radius: 5px !important;
}
.date {
  color: #333;
  font-weight: bold;
}
.percentage {
  width: 100%;
  height: 100%;
  border-radius: 0 !important
}
.percentage--wrapper {
  background-color: #DFDFE2;
  position: relative;
}
.percentage--bar {
  background-color: #626873;
  text-align: center;
  color: white;
  font-size: 12px;
}
.status {
  text-align: center;
  font-size: 12px;
  max-width: 120px;
  margin-left: auto;
}
.status--loading {
  background-color: #f7c707;
}
.status--error {
  background-color: lightcoral;
}
.status--success {
  background-color: lightgreen;
}
.error-message {
  color: red;
  font-size: 14px;
}
@media (max-width: 768px) {
  .navbar-collapse {
    border-top: 1px solid #e7e7e7;
    margin-top: 8px;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
